import { get, HttpResponse, post } from '@/api/Api';
import { api_url } from '@/api/thg/api';
import {
    VRCAnalysisResponseSchema,
    VRCCheckPayload,
    VRCCheckResponse,
    VRCPayload,
    VRCReplacePayload,
} from '@/interfaces/thg/vrc/VehicleRegistrationCertificate';
import { TokenType } from '@/enums/TokenType';
import { THGPresignedUrl } from '@/interfaces/thg/THGPresignedUrl';

/**
 * Check the given vrc-related data.
 *
 * @param payload
 */
export async function check(payload: VRCCheckPayload): Promise<HttpResponse<VRCCheckResponse>> {
    return await post<VRCCheckResponse>(
        `${api_url()}/crm/cars/vrc/check`,
        payload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Analyze the given vehicle registration certificate.
 *
 * @param payload image as base64
 */
export async function analyze(
    payload: VRCPayload,
): Promise<HttpResponse<VRCAnalysisResponseSchema>> {
    return await post<VRCAnalysisResponseSchema>(
        `${api_url()}/crm/cars/vrc/analyze`,
        payload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Replace the given vrc by a new one.
 *
 * @param payload
 */
export async function replace(payload: VRCReplacePayload): Promise<HttpResponse<THGPresignedUrl>> {
    return await post<THGPresignedUrl>(
        `${api_url()}/crm/cars/vrc/replace`,
        payload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Get the presigned url for retrieving VRC.
 *
 * @param file_key
 * @param folder optional folder (appended as query parameter)
 */
export async function getPresignedUrl(
    file_key: string,
    folder = '',
): Promise<HttpResponse<THGPresignedUrl>> {
    let path = `${api_url()}/crm/cars/vrc/pre_signed_url/${encodeURIComponent(file_key)}`;

    if (folder) {
        path = `${path}?folder=${folder}`;
    }

    return await get<THGPresignedUrl>(path, TokenType.THG_API_KEY);
}
